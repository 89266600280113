
import axios from "axios";

export default class Search {
  constructor(element) {
    
    this.el = element;
    this.page = 0;
    // vi tester med: formandens departement
    
    this.init();
  }

  init() {
    document.doSearch = this.doSearch;
    document.searchElement = this.el;
  }

  doSearch(input) {
    const el = document.searchElement;
    axios
        .post(`${window.location.origin}/api/searchapi/search`, {
          query: input,
          page: 0,
        })
        .then((response) => {
          el.innerHTML = JSON.stringify(response);
        });
  }
}