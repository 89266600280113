import axios from "axios";

export default class Accordions {
  constructor(element) {
    this.el = element;
    this.yearDropdown = this.el.querySelector("#yearDropdown");
    this.resultsLabel = this.el.querySelector("#resultsLabel");
    this.resultsContainer = this.el.querySelector("#resultsContainer");
    this.initialText = this.resultsLabel.innerText;
    this.configurationId = this.el.querySelector("#announcementConfiguration");
    this.init();
  }

  async init() {
    const years = (
      await axios.get("/api/announcement/GetAllAvailableAnnouncementYears")
    ).data;

    for (let i = 0; i < years.length; i++) {
      let yearOption = document.createElement("option");
      yearOption.value = years[i];
      yearOption.innerHTML = years[i];
      this.yearDropdown.appendChild(yearOption);
    }

    await this.renderAnnouncementsForSelectValue();

    this.yearDropdown.onchange = async () => {
      await this.renderAnnouncementsForSelectValue();
    };
  }
  CreateDiv = (firstElement, value) => {
    let outerDiv = document.createElement("div");
    outerDiv.classList = "pr-4";
    // Add classes that are not relevant for first child
    if (!firstElement) {
      outerDiv.classList  += " md:pl-4";
    }
    outerDiv.innerText = value;
    return outerDiv;
  };
  renderAnnouncementsForSelectValue = async () => {
    const value =
      this.yearDropdown.options[this.yearDropdown.selectedIndex].value;
    const query = "?name=" + value;
    const announcementData = (
      await axios.get("/api/announcement/GetAnnouncementsByYear" + query)
    ).data[0];
    const configurationData = (
      await axios.get(
        `/api/announcement/GetConfiguration?id=${this.configurationId.value}`
      )
    )?.data;

    this.resultsContainer.innerHTML = "";

    let announcementsRendered = 0;

    announcementData.Announcements.forEach((announcement) => {
      // only render announcements with media set
      if (
        announcement.Media.substring(announcement.Media.lastIndexOf(".")) ===
        ".pdf"
      ) {
        let announcementElement = document.createElement("a");
        announcementElement.href = announcement.Media;
        announcementElement.download = "";
        announcementElement.classList =
          "bg-white p-6 drop-shadow-md block transition duration-300 ease-in-out hover:-translate-y-2 mb-6 hover:no-underline no-underline";

        let announcementTopDiv = document.createElement("div");
        announcementTopDiv.classList =
          "flex flex-wrap items-center md:divide-x-2 md:divide-border-gray text-xs text-gray-dark mb-6";

        let numberDiv = this.CreateDiv(true, `Nr. ${announcement.Number}`);

        let announcedDiv = this.CreateDiv(false,`${configurationData.Announcement} ${announcement.DocumentDate}`);

        let effectiveDiv = this.CreateDiv(false,`${configurationData.Effective} ${announcement.EffectiveDate}`);

        let reprintDiv = (announcement.ReprintDate.length > 0) ?
         (this.CreateDiv( false, `${configurationData.Reprint}: ${announcement.ReprintDate}`) ) :
         (this.CreateDiv( false, `${configurationData.Reprint}`));        

        let nameFlexDiv = document.createElement("div");
        nameFlexDiv.classList = "flex justify-between items-center";

        let announcementNameDiv = document.createElement("div");
        announcementNameDiv.classList = "font-bold text-base text-[#222] mb-6";
        announcementNameDiv.innerText = announcement.Name;

        let announcementLabelList = document.createElement("ul");
        announcementLabelList.classList = "flex flex-wrap px-0 gap-y-2";

        announcement.RulesetSubjects.forEach((subject) => {
          let subjectListItemElement = document.createElement("li");
          subjectListItemElement.classList =
            "px-4 py-2 text-[#222] border text-sm text-normal mr-4 last:mr-0";
          subjectListItemElement.innerText = subject;

          announcementLabelList.appendChild(subjectListItemElement);
        });
        announcementTopDiv.appendChild(numberDiv);
        announcementTopDiv.appendChild(announcedDiv);
        announcementTopDiv.appendChild(effectiveDiv);
        if (announcement.Reprint) {
          announcementTopDiv.appendChild(reprintDiv);
        }

        nameFlexDiv.appendChild(announcementNameDiv);
        nameFlexDiv.innerHTML +=
          "<i class='ml-8 svg-interaction svg-interaction__download'></i>";

        announcementElement.appendChild(announcementTopDiv);
        announcementElement.appendChild(nameFlexDiv);
        announcementElement.appendChild(announcementLabelList);
        this.resultsContainer.appendChild(announcementElement);

        announcementsRendered++;
      }
    });

    this.renderResultsLabel(announcementsRendered, announcementData.Name);
  };

  renderResultsLabel = (resultsAmount, yearName) => {
    this.resultsLabel.innerText = this.initialText
      .replace("{number}", resultsAmount)
      .replace("{year}", yearName);
  };
}
