export default class Tooltip {
    constructor(element) {
      this.el = element;
      this.tooltip = document.querySelector('.tooltip');
      this.tooltipText = document.querySelector('.tooltip-text');
      this.init();
    }
  
    init() {
  
      // Check if element exxists.
      if (typeof(this.tooltip) != 'undefined' && this.tooltip != null)
      {
      
          this.tooltip.addEventListener("click", () => {
          
              let elText = this.tooltip.dataset.tooltipText;
          
  
              this.tooltipText.innerText = elText;
  
              console.log('---->', elText)
              
          })
      }
    }
  }
  