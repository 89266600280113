import axios from "axios";
export default class Searchbox {
  constructor(element) {
    this.el = element;
    this.suggestionsList = this.el.querySelector(".lstSuggestions");
    this.searchbox = this.el.querySelector(".tbSearchBox");
    this.typeAheadUrl = `${window.location.origin}/api/searchapi/typeahead`;    
    this.init();
  }

  init() {
    
    this.searchbox.addEventListener("input", (event) => {
      let value = event.srcElement.value;
      if (value.length < 2) {
        this.suggestionsList.innerHTML = "";
        this.suggestionsList.classList.remove('visible');
        this.searchbox.classList.remove('visible');
        return;
      }
      const url = this.typeAheadUrl;
      axios
        .post(url, {
          query: value,
        })
        .then((response) => {
          this.suggestionsList.innerHTML = "";
          
          if (response.data.Results.length) {
            this.suggestionsList.classList.add('visible');
              this.searchbox.classList.add('visible');
          }
          else {
            this.suggestionsList.classList.remove('visible');
            this.searchbox.classList.remove('visible');
          }

          response.data.Results.forEach((suggestion) => {
            let li = document.createElement('li');
            li.innerHTML = `<a href="#" class="hover:no-underline">${suggestion}</a>`;
            this.suggestionsList.append(li);

            li.addEventListener('click', () => {
              this.search(suggestion);
            });
          });
        });
    });

    this.searchbox.addEventListener('focus', () => {
      if(this.suggestionsList.childNodes.length) {
        this.suggestionsList.classList.add('visible');
      }
    });

    this.searchbox.addEventListener('keyup', e => {
      if (e.code === 'Enter' && e.target.value.length > 1)
        this.search(e.target.value)
    });
  }

  search(input) {
    this.searchbox.value = input;
    this.suggestionsList.classList.remove('visible');
    if(this.el.getAttribute('data-search'))
      document.doSearch(input);
  }
}
