import 'regenerator-runtime';
import './index.css';
import {EventBus, EventTypes} from './consts/const-event-types';
/*
To add new components, import them here and add them to the 'components'-array below.
Add an appropriate selectorName for use in DOM (fx: <div data-component="header"></div>):
*/
import App from './components/app';
// import Menu from './components/menu/Menu';
import Searchbox from './components/searchbox/searchbox';
import Search from './components/search/search';
import Accordions from './components/accordion/accordion';
import Menu from './components/menu/menu';
import Announcements from './components/announcements/announcements'
import LanguagePicker from './components/languagePicker/languagePicker';
import Tooltip from './components/tooltip/tooltip';

const components = [
  {Class: App, selectorName: 'app'},
  // {Class: Menu, selectorName: 'menu'},
  {Class: Searchbox, selectorName: 'searchbox'},
  {Class: Search, selectorName: 'search'},
  {Class: Accordions, selectorName: 'accordions'},
  {Class: Menu, selectorName: 'menu'},
  {Class: Announcements, selectorName: 'announcements'},
  {Class: LanguagePicker, selectorName: 'langPicker'},
  {Class: Tooltip, selectorName: 'tooltip'}
];

/*
* ComponentInstantiator traverses the DOM-tree and looks for [data-component='] attributes.
*/
class ComponentInstantiator {
  constructor() {
    this.createComponents();
    this.dispatchReady();
    return this;
  }

  createComponents() {
    this.componentsInstantiated = {};

    components.map(component => {
      const nodes = [];
      // select all DOM-nodes with the data-component attribute:
      const nodeItems = [...document.querySelectorAll('[data-component=\'' + component.selectorName + '\']')];

      nodeItems.map(nodeItem => {
        // instantiate component with nodeItem as param:
        nodes.push(new component.Class(nodeItem));
      });

      // add to componentsList object:
      if (nodes.length) this.componentsInstantiated[component.selectorName] = nodes;
    });
  }

  dispatchReady() {
    const event = new CustomEvent(EventTypes.ALL_COMPONENTS_READY);
    EventBus.dispatchEvent(event);
  }

  getComponents(selectorName) {
    return selectorName ? this.componentsInstantiated[selectorName] : this.componentsInstantiated;
  }
}

/* Make your App accessible via window.App */
window.App = new ComponentInstantiator();

/* Access components list */
// console.log('components on this page', window.App.getComponents())

// various global js functions
//InitSitecoreTables();
